.css-79ws1d-MuiModal-root {
  overflow-y: auto;
}
/* Upcoming Booking Table Starts */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}
.custom-table {
  border-spacing: 0 10px;
  width: 100%;
  border-collapse: separate;
}
.custom-table th {
  color: #4b5157;
  font-family: 'Avenir-regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 9px;
  white-space: nowrap;
}
.custom-table tbody tr {
  background: rgb(224, 228, 231);
  border-radius: 15px;
  padding: 20px 11px;
}
.custom-table tbody span,
.custom-table tbody h5,
.custom-table tbody .avatar-status span {
  color: #373737;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Avenir-medium';
}
.custom-table td {
  padding: 10px 9px;
}
.custom-table tbody h5 {
  color: #583ef2;
  margin: 0;
}
.custom-table td:first-child div {
  display: flex;
  align-items: center;
}
.custom-table td:first-child span {
  margin-left: 15px;
  width:0% !important
}
.custom-table td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.custom-table td:last-child {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}
.custom-table tbody tr td {
  /* width: 22%; */
}
.custom-table tbody tr td:nth-child(3) {
  width: 20%;
}
.custom-table tbody tr td:nth-child(4) span {
  white-space: nowrap;
}
.btn-group {
  display: flex;
  /* justify-content: flex-end; */
}
.btn-group button {
  margin-left: 10px;
}
.msg-btn {
  border: 2px solid rgba(129, 129, 215, 0.2);
  border-radius: 12px;
  width: auto;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
}
.msg-btn.star {
  text-align: center;
  display: flex;
  align-items: center;
  justify-self: center;
}
.dashBoardImages {
  height: 37px;
  width: 38px;
  border-radius: 20px;
}

.msg-btn img {
  margin-right: 3px;
}
.msg-btn div {
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  margin-top: 3px;
  line-height: 22px;
  font-family: 'Avenir-medium';
}
.raise-btn {
  background: rgba(247, 101, 139, 0.2);
  border: 1px solid rgba(247, 101, 139, 0.2);
  border-radius: 12px;
  padding: 3px 15px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  white-space: nowrap;
  font-family: 'Avenir-medium';
}
.view_btn {
  background: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 8px;
  padding: 3px 39px;
  color: #002a73;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  font-family: 'Avenir-regular';
  display: table;
  margin: 22px auto 0;
}
.custom-table.pastBooking-table tr {
  background-color: transparent;
}
.custom-table.pastBooking-table td {
  border-top: 1px solid hsl(210deg 3% 85% / 40%);
}
.custom-table.pastBooking-table td:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-table.pastBooking-table td:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.custom-table.pastBooking-table {
  border-spacing: 0;
}
.custom-table.pastBooking-table img {
  height: 37px;
  width: 38px;
  border-radius: 50%;
}
.transaction_icon {
  height: 34px;
    /* width: 44px !important; */
    border-radius: 50%;
}
.custom-table.pastBooking-table .star-icon {
  width: 13px !important;
}
@media (min-width: 1200px) {
  .manageStaff_main .transition_table.custom-table thead th:first-child {
    padding-left: 53px;
  }
}
/* Upcoming Booking Table Starts Ends*/

/* Job Page Starts */
button {
  cursor: pointer;
}
.jobs-header .jobs_btns button {
  border-radius: 10px;
  background: rgb(48 160 224 / 20%);
  color: #708090;
  border: none;

}
.job-details {
  color: #000000;
  font-weight: 500;
  font-family: 'Avenir-medium';
  font-size: 12px;
  line-height: 24px;
  white-space: nowrap;
  border: 2px solid rgb(129 129 215 / 20%);
  border-radius: 12px;
  background: transparent;
  padding: 3px 20px;
}
.job-details button :disabled {
  cursor: 'not-allowed';
}
.filter_dropdowm {
  position: absolute;
  background: #fff;
  width: 217px;
  top: 29px;
  left: -206px;
  box-shadow: 0 0 5px #ccc;
  border-radius: 9px;
  padding: 4px 20px 5px 20px;
}
.filter_li {
  position: relative;
}
.jobStatus {
  font-size: 11px;
  margin: 11px 0px 5px 0px;
  opacity: 0.8;
}
.filterJobHeading {
  margin: 15px 0px 0px 0px;
  font-size: 14px;
  opacity: 0.8;
}
.filter_dropdowm label {
  font-size: 13px;
  opacity: 0.8;
}
.filter_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.8;
}
.contents {
  display: flex;
}
.contents input,
.contents h6 {
  margin: 8px 0px 0px 0px;
  font-size: 12px;
}
/* .contents input[type="checkbox"]{
  outline:1px solid #ededed;
 

} */
.filter_apply {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  background: #262626;
  margin: 10px 0px 10px 0px;
  color: #fff;
  cursor: pointer;
}
.custom-table.custom-table .active td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.custom-table.custom-table .active td:last-child {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}
/* .custom-table.custom-table:hover {
  background: var(--custom-blue);
  border-top: none;
  color:#fff
} */
.custom-table.custom-table tbody tr:hover * {
  color: #fff !important;
}
.custom-table.custom-table tbody tr:hover {
  background: var(--custom-blue);
}

.custom-table.custom-table tbody tr:hover .msg-btn.star {
  color: #000000 !important;
}
.custom-table.custom-table .active td span {
  color: #fff;
}
.custom-table.custom-table .active td h5 {
  color: rgb(255 255 255 / 50%);
}
.custom-table.custom-table .active .job-details {
  background-color: #fff;
  border-color: #fff;
}
.jobs-header {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.jobs-header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.jobs-header ul li {
  margin-left: 10px;
}
.jobs-header button {
  background: var(--neutral-white);
  padding: 6px 14px;
  font-weight: 400;
  font-family: 'Avenir-regular';
  font-size: 12px;
  color: #0e4c95;
  line-height: 16px;
  display: flex;
  align-items: center;
  border: 1px solid #0e4c95;
  border-radius: 10px;
}
.jobs-header button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.jobs-header button img {
  margin-left: 6px;
}
.jobs-header .jobs_btns .filter-btn {
  background: rgb(194 127 144 / 20%);
}
.w-100 {
  width: 100%;
}
.filter_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* Job Page Ends */

/* Job Modal Starts */
.icon-close {
  position: relative;
  width: 13px;
  height: 13px;
  flex-shrink: 0;
  opacity: 0.3;
  cursor: pointer;
}
.jobModal_box {
  font-family: var(--font-avenir);
}
.jobModal_box .headerAlign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.jobModal_box .job-heading {
  color: #202034;
  font-weight: 500;
  font-size: 20px;
  line-height: 12px;
  margin: 0;
  font-family: 'Avenir-medium';
}
.jobModal_box .line {
  align-self: stretch;
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  flex-shrink: 0;
  margin: 22px 0;
}
.hour,
.line,
.pic-icon {
  position: relative;
}
.pic-icon {
  border-radius: var(--br-mini);
  width: 133px;
  height: 143px;
  flex-shrink: 0;
  overflow: hidden;
}
.hour {
  line-height: 16px;
}
.label {
  border-radius: var(--br-3xs);
  border: 1px solid var(--forecolor1);
  display: flex;
  flex-direction: row;
  padding: 6px var(--padding-sm);
  align-items: center;
  justify-content: center;
}
.title {
  margin: 0;
  position: relative;
  font-size: var(--font-size-3xl);
  line-height: 28px;
  font-weight: 500;
  font-family: 'Avenir-medium';
  color: #1f126b;
  display: inline-block;
  width: 143px;
}
.nonsubscriptionTitle{
  font-family: 'Avenir-regular';
  font-size: 16px;
  padding: 0 0 10px;
  margin:0px;
}
.nonSubscriptContent{
  font-family: var(--font-avenir);
  color: #343434;
}
.nonIcon{
  padding: 0 0 0 5px;
    margin: 4px;
    font-size: 16px;
    color: #F7658B;
}
.viewModal {
  width: 55%;
  background: #002147;
  position: absolute;
  z-index: 999;
  height: 77%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #fff;
  font-family: var(--font-avenir);
  border-radius: 21px;
}
.viewModal .content{
  display: flex;
    align-items: center;
    height: 100%;
    padding: 28px 0 0 0;
}
.button_success{
  margin: 100px 0 0 0 !important;
  background: #fff !important;
  padding: 9px 85px !important;
  border-radius: 13px !important;
 
}
.definition{
  width: 72%;
    text-align: center
}
.successPage{
  content: "";
  background: #00000052;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;
}
.label-parent,
.repair-heading {
  display: flex;
  justify-content: flex-start;
}
.label-parent {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.repair-heading {
  align-self: stretch;
  border-radius: 2px var(--br-mini) var(--br-mini) var(--br-mini);
  background-color: rgba(129, 129, 215, 0.05);
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xl);
}
.vector-icon {
  position: relative;
  width: 14.29px;
  height: 14.29px;
  flex-shrink: 0;
}
.testimonial-icon {
  margin: 0 !important;
  position: absolute;
  top: 13px;
  right: 14.71px;
  border-radius: 5px;
  background-color: #f4f4f4;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 7px;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.image-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  width: 58px;
  height: 58px;
  object-fit: cover;
}
.image,
.vector-icon1 {
  position: relative;
  flex-shrink: 0;
}
.image {
  width: 58px;
  height: 58px;
  overflow: hidden;
  z-index: 1;
}
.vector-icon1 {
  width: 9.17px;
  height: 8.72px;
}
.h6 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: 'Avenir-regular';
}
.rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  font-size: 11px;
}
.albert-flores,
.hour1 {
  margin: 0;
  position: relative;
}
.albert-flores {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'Avenir-medium';
  color: var(--color-darkslategray-100);
}
.hour1 {
  font-size: inherit;
  font-weight: 700;
  font-family: var(--font-avenir);
  opacity: 0.5;
}
.amet-minim-mollit {
  margin: 0;
  position: relative;
  font-family: var(--font-avenir);
  color: #858585;
  display: inline-block;
  width: 457px;
}
.clients-review,
.content {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  flex-direction: column;
  gap: 6px;
  z-index: 2;
}
.clients-review {
  border-radius: var(--br-mini);
  border: 1px solid rgba(129, 129, 215, 0.37);
  flex-direction: row;
  padding: var(--padding-mini);
  position: relative;
  gap: 15px;
  color: var(--color-black);
  font-family: var(--font-inter);
  width: 100%;
}
.modal_size {
  max-height: 500px;
  width: 40%;
  overflow-y: auto;
}
body .modal_equipment {
  width: auto;
  border: none;
  max-height: calc(100vh - 60px);
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 11px;
  padding: 0;
  top: 50% !important;
}
body .modal_equipment > div {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 20px;
}
.verification_cross {
  position: absolute;
  top: 25px;
  right: 21px;
  height: 13px;
  cursor: pointer;
}
.job-details2 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 32px;
  font-weight: 400;
  font-family: 'Avenir-regular';
  color: #002a73;
  text-align: center;
}
.job-details2.addNew {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1f126b;
}
.body,
.job-desc {
  margin: 0;
  position: relative;
  display: inline-block;
}
.job-desc {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  font-family: var(--font-avenir);
  color: #1f1f39;
}
.body {
  font-size: var(--caption-14px-medium-size);
  line-height: 24px;
  color: var(--color-darkslateblue);
}
.input-field {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-family: var(--font-poppins);
}
.img-icon,
.job-details-child {
  position: relative;
  flex-shrink: 0;
}
.job-details-child {
  width: 100%;
  height: 1px;
  border: 1px solid #eaeaff;
  display: block;
}
.modal_equipment #modal-modal-description {
  font-size: var(--caption-14px-medium-size);
  line-height: 24px;
  color: var(--color-darkslateblue);
}
.img-icon {
  width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 15px;
  object-fit: cover;
}
.body1,
.heading {
  margin: 0;
  position: relative;
}
.heading {
  font-size: inherit;
  line-height: 18px;
  font-weight: 500;
  font-family: 'Avenir-medium';
}
.body1 {
  font-size: var(--small-12px-regular-size);
  line-height: 16px;
  font-family: var(--font-poppins);
  color: #78789d;
  display: inline-block;
  width: 89px;
}
.desc,
.equipment-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.equipment-1 {
  border-radius: var(--br-mini);
  border: 1px solid var(--color-lavender);
  box-sizing: border-box;
  width: 30%;
  height: 71px;
  flex-shrink: 0;
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-sm);
  align-items: center;
  gap: var(--gap-8xs);
  font-size: var(--caption-14px-medium-size);
  color: var(--rowner-color);
}
.monday-28 {
  display: block;
}
.input-field1,
.job-details1 {
  align-items: flex-start;
  justify-content: flex-start;
}
.input-field1 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xl);
  font-family: var(--font-poppins);
}
.job-details1 {
  height: auto;
  flex-shrink: 0;
  gap: 25px;
  width: 100%;
  color: var(--color-gray-200);
}
.job-cotent,
.job-details1,
.modal {
  display: flex;
  flex-direction: column;
}
.job-cotent {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--small-12px-regular-size);
  color: var(--forecolor1);
}
.MuiModal-root .MuiBox-root.css-1acyv19 {
  width: auto;
  height: auto;
  overflow-y: auto;
}
/* Manage Staff Page Starts */
.manageStaff_main {
  flex-direction: row !important;
  height: auto;
  flex: auto;

}
.manageStaff_main1{
  padding-left: 40px !important;
}
.manageStaff_main1 .custom-table th:nth-child(2),
.manageStaff_mainPayment .custom-table tr td:nth-child(1) {
  /* width: 23% !important;
  max-width: 23% !important; */
}
.manageStaff_main tbody span,
.manageStaff_main tbody h5 {
  color: #686666;
  font-size: 13px;
  line-height: 100%;
}
.manageStaff_main tbody h5 {
  text-transform: capitalize;
}
.staff-member-card .MuiInputLabel-root {
  color: #b8b8d2;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Avenir-regular';
}
.staff-member-card .css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid #eaeaff;
}
.avatar-status {
  display: flex;
  align-items: center;
}
.avatar-status span {
  margin-left: 15px;
}
.manageStaff_main .staff-member-card {
  margin-left: 40px;
}
.manageStaff_cont {
  width: 71%;
}
.manageStaff_main .body3,
.manageStaff_main .title1 {
  margin: 0;
  position: relative;
  font-weight: 500;
  font-family: 'Avenir-medium';
}
.manageStaff_main .title1 {
  font-size: var(--font-size-base);
  line-height: 24px;
  color: var(--custom-blue1);
}
.manageStaff_main .email-field {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  color: #002a73;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Avenir-regular';
  border-bottom: 1px solid hsl(210deg 3% 85% / 58%);
}
.manageStaff_main .email-field:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.manageStaff_main .input-field2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.manageStaff_main .input-field2 select{
  font-family:var(--font-avenir)
}
.manageStaff_main .buttonmain,
.manageStaff_main .input-field2 {
  align-self: stretch;
}
.manageStaff_main .staff-member-card {
  flex: 1;
  border-radius: 25px;
  border: 1px solid var(--color-lavender);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) 19px;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  width: 30%;
}
.manageStaff_main .body3 {
  font-family: 'Avenir-medium';
  font-weight: 500;
  line-height: 16px;
  color: var(--color-gray-200);
  display: inline-block;
  font-size: 12px;
}
.manageStaff_main .body4 {
  margin: 0;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Avenir-regular';
  color: #b8b8d2;
  display: inline-block;
}
.manageStaff_main .buttonmain {
  background: #002a73;
  border-radius: 14px;
  padding: 11px 43px;
  color: #ffffff;
  font-family: 'Avenir-black';
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  white-space: nowrap;
  border: 1px solid #002a73;
}
.manageStaff_main .custom-table thead th:first-child,
.manageStaff_main .custom-table tr td:first-child {
  width: 8%;
  max-width: 8%;
}
.manageStaff_main .staff-member-card {
  margin-top: 48px;
}
.manageStaff_main .form-group {
  position: relative;
}
.manageStaff_main .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 16px;
  z-index: 1;
  height: 18px;
  left: -4px;
  top: -2px;
}
.manageStaff_main .form-group span {
  position: relative;
}
.manageStaff_main .form-group span:before {
  content: '';
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: #eaeaff;
  border-radius: 4px;
}
.manageStaff_main .form-group input:checked + span:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  width: 4px;
  height: 8px;
  border: solid #071548;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.manageStaff_main1 .custom-table td:first-child span {
  margin-left: 0;
}
@media (max-width: 767px) {
  .manageStaff_main {
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }
  .manageStaff_main .staff-member-card {
    margin-top: 32px;
    margin-left: 0;
    width: 90%;
  }
  .successHeading{
    text-align: center;
  }
  .button_success{
    width: 20%;
    height: 10%;
  }
}
/* Manage Staff Page Ends */

/* Edit Staff Modal Starts */
.editStaff_modal .MuiBox-root.css-1acyv19 {
  padding: 0;
}
.editStaff_modal .name-field:focus-visible,
.editStaff_modal .select-input:focus-visible,
.editStaff_modal .email-field1:focus-visible {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.editStaff_modal .name-field,
.editStaff_modal .select-input,
.editStaff_modal .email-field1 {
  color: #002a73;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Avenir-regular';
}
.editStaff_modal .MuiSwitch-root {
  width: 40px;
  height: 24px;
  border-radius: 8px;
  padding: 0;
}
.editStaff_modal .MuiSwitch-switchBase {
  padding: 4px;
}
.editStaff_modal .MuiSwitch-thumb {
  border-radius: 5px;
  width: 16px;
  height: 16px;
  background-color: #fff;
}
.editStaff_modal .MuiSwitch-track {
  border-radius: 7px;
  background-color: #0e4c95 !important;
  opacity: 1 !important;
}
.editStaff_modal
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  -webkit-transform: translateX(16px);
  -moz-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
.css-ahj2mt-MuiTypography-root {
  font-family: 'Avenir-medium';
  color: #1f1f39;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;
}
/* Edit Staff Modal Ends */

/* Payment History Starts */
.manageStaff_mainPayment .custom-table th:nth-child(2),
.manageStaff_mainPayment .custom-table td:nth-child(2) {
  width: 12%;
  max-width: 12%;
}
.manageStaff_mainPayment .custom-table th:nth-child(4),
.manageStaff_mainPayment .custom-table td:nth-child(4) {
  width: 10%;
  max-width: 10%;
}
.custom-table .view_invoice {
  color: #202034;
  display: flex;
  align-items: center;
}
.custom-table .view_invoice img {
  width: 15px;
  margin-right: 5px;
}
.custom-table .view_invoice span {
  margin-right: 5px;
}
.manageStaff_mainPayment .custom-table tbody tr td {
  width: auto;
}
.upcoming-bookings span {
  color: #939699;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-left: 4px;
  font-weight: 400;
  font-family: 'Avenir-regular';
}
.manageStaff_mainPayment .custom-table.pastBooking-table td h4 {
  color: #002147;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  font-family: 'Avenir-regular';
}
.subscribe_btn {
  background: rgb(14 76 149 / 10%);
  border: none;
  padding: 12px;
  border-radius: var(--br-14);
  color: #0e4c95;
  font-weight: 500;
  font-family: 'Avenir-medium';
  font-size: var(--small-12px-regular-size);
  line-height: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscribe_btn img {
  margin-left: 15px;
}
.visa-details {
  display: flex;
  padding: 20px 15px;
  font-family: var(--font-avenir);
  border: 1.5px solid rgba(129, 129, 215, 0.1);
  border-radius: 15px;
  width: 100%;
  margin: 10px 0;
  align-items: center;
  position: relative;
}
.visa-details .MuiIconButton-root {
  color: rgb(177, 177, 177);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.visa-details .visa_cont {
  margin-left: 18px;
}
.visa-details h4 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #373737;
  font-family: 'Avenir-medium';
}
.visa-details p {
  font-weight: 400;
  font-family: 'Avenir-regular';
  font-size: 12px;
  line-height: 16px;
  color: #858585;
  margin: 0;
}
.edit_btn.view_btn {
  margin: 0;
  width: 100%;
  padding: 13px;
  font-size: 14px;
  border-radius: 14px;
}
/* Payment History Ends */

/* Equipment Page Starts */
.seach-input {
  background: rgb(184 184 210 / 20%);
  border-radius: 15px;
  position: relative;
}
.seach-input:before {
  content: '';
  background: url(../svg/search-icon.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  z-index: 9;
  position: absolute;
  top: 13px;
  left: 16px;
  height: 24px;
  width: 24px;
}
/* .seach-input:after {
  content: '';
  background: url(../svg/sliders.svg);
  background-repeat: no-repeat;
  background-size: 16px;
  z-index: 1;
  position: absolute;
  top: 13px;
  right: 20px;
  height: 16px;
  width: 16px;
} */
.seach-input input {
  padding: 13px 16px 13px 55px;
  border: none;
  background: transparent;
  color: rgb(120 120 157 / 70%);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Avenir-regular';
  width: 81%;
}
.seach-input input:focus {
  outline-offset: 0;
  outline: none;
  box-shadow: none;
}
.search-input {
  position: relative;
  margin-top: -2px;
}
.search-input input:focus-visible {
  outline-offset: 0;
  outline: none;
  box-shadow: none;
}
.search-input:before {
  content: '';
  background: url(../svg/search-icon.svg);
  background-repeat: no-repeat;
  background-size: 17px;
  z-index: 9;
  position: absolute;
  top: 8px;
  left: 16px;
  height: 24px;
  width: 24px;
}
.search-input input {
  padding: 3px 16px 3px 55px;
  border: none;
  background: rgb(184 184 210 / 20%);
  color: rgb(120 120 157 / 70%);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Avenir-regular';
  width: 100%;
  border-radius: 12px;
}
.equipment_header .second_ul {
  width: 45%;
}
.equipment_header .second_ul .btn-filters li:last-child {
  width: 80%;
}
.equipment_header.jobs-header button {
  white-space: nowrap;
  padding: 15px 20px;
}
/* Equipment Page Ends */

/* Subscription Page Starts */
.subscription_wrapper {
  gap: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.subscription_header .jobs_btns button {
  padding: 16px 35px 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #0e4c95;
  border-radius: 14px;
  font-family: 'Avenir-medium';
}
.subscription_header .jobs_btns .focused {
  background: #7dc664;
}
.subscription_header .jobs_btns li:last-child img {
  margin-right: 4px;
}
@media (max-width: 767px) {
  .subscription_wrapper {
    flex-wrap: wrap;
  }
  .jobs-header {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .subscription_header .jobs_btns button {
    padding: 12px 35px 12px;
  }
  .jobs-header ul li {
    margin-right: 10px;
    margin-left: 0;
  }
  .upcoming-bookings {
    margin: 0 0 10px;
  }
}
/* Subscription Page Ends */

.paginate {
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 36px 0 0 0;
}
.paginate.payment {
  margin: 0 0 40px 0;
}
body .css-1wnsr1i > div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

body .css-1wnsr1i > div::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

body .css-1wnsr1i > div::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
}
.errors {
  color: red;
  font-size: 12px;
  font-family: 'Avenir-regular';
  font-weight: 400;
}
.errors::before {
  display: inline;
  content: '⚠ ';
}

.equipment-id .alert {
  color: red;
  font-size: 14px;
}

.invoice--services {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(129, 129, 215, 0.05);
  border-radius: 15px;
  padding: 15px;
  width: 100%;
  color: #212121;
}
.services--right {
  text-align: end;
}
.services--desc {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #373737;
}
.invoice--services h5 span {
  margin: 0;
  color: #858585;
}

.invoice--services h5 {
  margin: 0;
  font-weight: 400;
  font-family: 'Avenir-regular';
  font-size: 12px;
  line-height: 16px;
  color: #583ef2;
}

.invoice--services h6 {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #858585;
  font-family: 'Avenir-regular';
}
.invoice--services h4 {
  margin: 5px 0 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #373737;
}
.invoice--services h3 {
  margin: 5px 0 0 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #373737;
}
.invoice-table {
  width: 100%;
  border: 1px solid #eaeaff;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #38385e;
  padding: 20px 21px 20px 21px;
  font-family: 'Avenir-regular';
}
.invoice-table.Table--photo tr td img {
  border-radius: 20px;
}
.invoice--bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.invoice-table tr td {
  border-bottom: 0.1px solid #f2f2f2;
  padding: 10px 0 10px 0;
}
.black--button::before {
  content: '';
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  background-color: #a4a4a7;
  margin: 1px 3px 0px 1px;
  padding: 3px;
}
.verification_modal {
  background: #002147;
  text-align: center;
}
.invoice-table.Table--photo tr td {
  border-bottom: none;
  padding: 10px 0 10px 0;
}
.job-equipment {
  width: 100%;
  height: auto;
  gap: 20px;
  padding: 12px;
}
.job-equipment ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 5px 14px;
  list-style: none;
}
.job-equipment ul li {
  width: 48%;
  padding: 4px 0;
}
.job-equipment .Name {
  width: 100%;
}
.job-equipment .jobEqp-img {
  width: 20%;
}
.job-equipment .jobEqp-img img {
  height: auto;
  width: 100%;
}
.job-equipment .desc {
  width: 80%;
}
.job-equipment .desc label 
.job-equipment .desc label {
  color: #373737;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  font-family: 'Avenir-medium';
}
.job-equipment .desc .txt {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  padding: 3px;
  font-size: 12px;
  font-family: var(--font-avenir);
  margin-top: 3px;
}
.job-equipment .desc .txt:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.worklog_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}
.worklog_wrapper .worklog-cont {
  width: 26%;
}
.worklog_wrapper .worklog-cont p {
  margin: 0 0 10px;
}
.worklog_wrapper .worklog-cont img {
  border-radius: 20px;
}
.invide_card .job-heading {
  font-size: 18px;
}
.invide_card p {
  margin: 11px 0 27px;
  line-height: 21px;
  color: #78789d;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Avenir-regular';
}
.jobModal_box .close,
.edit-staff-modal .close {
  width: 20px;
  cursor: pointer;
}
@media (max-width: 1200px) {
  #root {
    display: block !important;
    background-color: #fff;
  }
}
@media (max-width: 960px) {
  .right-side-content {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .manageStaff_main .manageStaff_cont {
    width: 100%;
  }
  .job-equipment ul li {
    width: 46%;
    padding: 2px 0;
  }
  .modal_equipment .equipment-1 {
    width: 100%;
  }
  .equipment-1.job-equipment {
    flex-wrap: wrap;
  }
  .job-equipment .desc {
    width: 100%;
  }
  .job-equipment .jobEqp-img {
    width: 40%;
  }
  .pic-icon {
    border-radius: var(--br-mini);
    width: 102px;
    height: 93px;
  }
  .equipment_header .second_ul {
    width: 100%;
    margin-top: 12px;
  }
  .equipment_section .equipment-card2 {
    width: 100%;
  }
  .services--right {
    text-align: left;
  }
  .invoice--services {
    flex-wrap: wrap;
    gap: 14px;
  }
}

